import { forwardRef } from 'react';

import classNames from 'classnames';

import { isFalse, isNotSet, isSet } from '@src/utils';

export const Input = forwardRef<
  HTMLInputElement,
  ElementPropsWithoutRef<'input'> & {
    error?: any;
    disableReadOnlyStyle?: string;
  }
>(function ({ className, error, disableReadOnlyStyle, ...props }, ref) {
  return (
    <input
      ref={ref}
      {...props}
      className={classNames(
        className,
        'py-2.5 px-4',
        'w-full',
        'rounded-lg border border-solid',
        {
          'border-grey-5': isNotSet(error),
          'border-red': isSet(error) && !isFalse(error),
        },
        'text-base placeholder-grey-4',
        { 'read-only:bg-grey-6': !disableReadOnlyStyle }
      )}
    />
  );
});
