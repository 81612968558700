import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Control, FieldPath, useController } from 'react-hook-form';

import classNames from 'classnames';

import getDaysInMonth from 'date-fns/getDaysInMonth';
import setDateFn from 'date-fns/set';

import {
  between,
  createTaiwanDate,
  getTaiwanTime,
  isEmptyString,
  refMerge,
} from '@src/utils';

import Flexbox from '@src/component/common/Flexbox';

interface BirthdayFieldComponent extends CustomizeFunctionComponent {
  <FormValues, Name extends FieldPath<FormValues>>(
    props: PropsWithChildren<{
      className?: string;
      control: Control<FormValues>;
      name: Name;
    }>,
    context?: any
  ): React.ReactElement | null;
}

/**
 * @description the value return by BirthdayField will be converted to TaiwanDate
 */
export const BirthdayField: BirthdayFieldComponent = ({
  className,
  control,
  name,
}) => {
  const [year, setYear] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [yearError, setYearError] = useState<boolean>(false);
  const [monthError, setMonthError] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);

  const yearRef = useRef<HTMLInputElement | null>(null);
  const monthRef = useRef<HTMLInputElement | null>(null);
  const dateRef = useRef<HTMLInputElement | null>(null);
  const isInitialized = useRef<boolean>(false);

  const {
    field: { onChange, onBlur, ref, value },
  } = useController({
    control,
    name,
    rules: {
      required: {
        value: true,
        message: '請輸入正確的日期格式。',
      },
    },
  });

  useEffect(() => {
    return () => {
      isInitialized.current = false;
    };
  }, []);

  useEffect(() => {
    if (value) {
      if ([year, month, date].includes('')) {
        setYear(`${(value as Date).getFullYear()}`);
        setMonth(`${(value as Date).getMonth() + 1}`);
        setDate(`${(value as Date).getDate()}`);
      }
    }
  }, [value]);

  useEffect(() => {
    if (isEmptyString(year) || isEmptyString(month) || isEmptyString(date))
      return;
    if (!isInitialized.current) {
      isInitialized.current = true;
      return;
    }

    if (yearError || monthError || dateError) {
      onChange(undefined);
    } else {
      const birthday = createTaiwanDate(
        new Date(
          parseInt(year, 10),
          parseInt(month, 10) - 1,
          parseInt(date, 10)
        )
      );
      onChange(birthday);
    }
  }, [year, month, date, yearError, monthError, dateError]);

  const onYearChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = event.target.value ?? '';
      const formattedValue = value.replace(/[^0-9]/g, '').slice(0, 4);
      setYear(formattedValue);
      const currentYear = getTaiwanTime().getFullYear();
      if (!between(parseInt(formattedValue), currentYear - 100, currentYear)) {
        setYearError(true);
      } else {
        setYearError(false);
        if (formattedValue.length === 4) monthRef.current?.focus();
      }
    },
    []
  );
  const onMonthChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = event.target.value ?? '';
      const formattedValue = value.replace(/[^0-9]/g, '').slice(0, 2);
      setMonth(formattedValue);
      if (!between(parseInt(formattedValue), 1, 12)) {
        setMonthError(true);
      } else {
        setMonthError(false);
        if (formattedValue.length === 2) dateRef.current?.focus();
      }
    },
    []
  );
  const onDateChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = event.target.value ?? '';
      const formattedValue = value.replace(/[^0-9]/g, '').slice(0, 2);
      setDate(formattedValue);
      const daysInMonth = getDaysInMonth(
        setDateFn(new Date(), {
          year: parseInt(year, 10),
          month: parseInt(month, 10),
          date: 0,
        })
      );
      if (!between(parseInt(formattedValue), 1, daysInMonth)) {
        setDateError(true);
      } else {
        setDateError(false);
      }
    },
    [year, month]
  );

  return (
    <Flexbox
      align={'center'}
      className={classNames(className, 'w-full overflow-hidden')}
    >
      <div className={'flex-1'}>
        <input
          ref={refMerge(yearRef, ref)}
          value={year}
          onChange={onYearChange}
          onBlur={onBlur}
          inputMode={'numeric'}
          className={classNames(
            'py-2.5 px-3',
            'w-full',
            'rounded-lg border border-solid'
          )}
          placeholder={'西元年'}
        />
      </div>
      <span className={'block mx-2'}>/</span>
      <div className={'flex-1'}>
        <input
          ref={refMerge(monthRef, ref)}
          value={month}
          onChange={onMonthChange}
          onBlur={onBlur}
          inputMode={'numeric'}
          className={classNames(
            'py-2.5 px-3',
            'w-full',
            'rounded-lg border border-solid'
          )}
          placeholder={'月份'}
        />
      </div>
      <span className={'block mx-2'}>/</span>
      <div className={'flex-1'}>
        <input
          ref={refMerge(dateRef, ref)}
          value={date}
          onChange={onDateChange}
          onBlur={onBlur}
          inputMode={'numeric'}
          className={classNames(
            'py-2.5 px-3',
            'w-full',
            'rounded-lg border border-solid'
          )}
          placeholder={'日期'}
        />
      </div>
    </Flexbox>
  );
};
