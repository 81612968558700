import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Control, FieldPath, useController } from 'react-hook-form';

import classNames from 'classnames';
import { chunk } from 'lodash';

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

import {
  isEmptyString,
  isNotEmptyArray,
  isNotEmptyString,
  isNotSet,
  isSet,
  isString,
  stopPropagation,
} from '@src/utils';

import Button from '@src/component/common/Button';
import ConditionalFragment from '@src/component/common/ConditionalFragment';
import Flexbox from '@src/component/common/Flexbox';
import Modal from '@src/component/common/Modal';

export interface RenderDisplayProps {
  displayValue: string | undefined | null;
  hasSelected: boolean;
}

interface SelectComponent extends CustomizeFunctionComponent {
  <FormValues, Name extends FieldPath<FormValues>>(
    props: PropsWithChildren<{
      chevronClassName?: string;
      className?: string;
      options: Array<Option>;
      control: Control<FormValues>;
      name: Name;
      placeholder?: string;
      rules?: Omit<
        RegisterOptions<FormValues, Name>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >;
      isMultiple?: boolean;
      renderDisplay?: React.FC<RenderDisplayProps>;
      disableBolder?: boolean;
      disablePadding?: boolean;
    }>,
    context?: any
  ): React.ReactElement | null;
}

export const Select: SelectComponent = ({
  className,
  chevronClassName,
  name,
  control,
  placeholder,
  options,
  rules,
  isMultiple = false,
  renderDisplay,
  disableBolder,
  disablePadding,
}) => {
  const [isOptionOpen, setIsOptionOpen] = useState<boolean>(false);

  const {
    field: { value, onChange },
  } = useController({ control, name, rules });

  const toggleOptions = useCallback(() => {
    setIsOptionOpen((prev) => !prev);
  }, []);

  const onItemClick = useCallback(
    (key: string | number) => {
      if (isMultiple) {
        let result = (value ?? []) as Array<string | number>;
        onChange(
          result.includes(key)
            ? result.filter((existKey) => existKey !== key)
            : result.concat(key)
        );
      } else {
        onChange(key);
        toggleOptions();
      }
    },
    [isMultiple, value]
  );

  const displayValue = useMemo(() => {
    if (!isMultiple) {
      return options.find((option) => option.key === value)?.label || null;
    } else {
      const result = options
        .filter(({ key }) =>
          ((value as Array<string | number>) ?? []).includes(key)
        )
        ?.map(({ label }) => label)
        .join('、');
      return isEmptyString(result) ? null : result;
    }
    // eslint-disable-next-line
  }, [value, isMultiple, options]);

  const displayOptions = useMemo(() => chunk(options, 3), [options]);

  return (
    <React.Fragment>
      <Modal
        show={isOptionOpen}
        className={'px-4 py-10'}
        onClick={toggleOptions}
      >
        <Flexbox
          onClick={stopPropagation}
          direction={'column'}
          className={
            'max-h-full w-full bg-white divide-y divide-grey-5 rounded-xl'
          }
        >
          <div className={'py-3 px-6'}>
            <p className={'font-bold'}>{placeholder}</p>
            <p className={'text-2sm text-grey-3'}>
              <ConditionalFragment condition={isMultiple}>
                複選
              </ConditionalFragment>
              <ConditionalFragment condition={!isMultiple}>
                單選
              </ConditionalFragment>
            </p>
          </div>
          <div className={'flex-1 p-4 overflow-scroll'}>
            {displayOptions.map((row, index) => (
              <Flexbox key={index} className={'mb-4 last:mb-0 text-2sm'}>
                {row.map(({ label, key }) => {
                  let isSelected;
                  if (isMultiple)
                    isSelected = (
                      (value as Array<string | number>) ?? []
                    ).includes(key);
                  else isSelected = value === key;

                  return (
                    <Flexbox
                      key={key}
                      align={'center'}
                      justify={'center'}
                      className={classNames(
                        'flex-1 mr-4 last:mr-0',
                        'rounded-extreme',
                        {
                          'text-white bg-pink font-bold': isSelected,
                          'border border-solid border-grey-5': !isSelected,
                        }
                      )}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        onItemClick(key);
                      }}
                    >
                      <p className={'py-1 text-center'}>{label}</p>
                    </Flexbox>
                  );
                })}
                <ConditionalFragment condition={row.length < 3}>
                  <span className={'flex-1'} />
                </ConditionalFragment>
                <ConditionalFragment condition={row.length < 2}>
                  <span className={'flex-1'} />
                </ConditionalFragment>
              </Flexbox>
            ))}
          </div>
          <ConditionalFragment condition={isMultiple}>
            <div className={'p-4'}>
              <Button
                buttonType={'pink'}
                onClick={toggleOptions}
                className={'w-full'}
              >
                確定
              </Button>
            </div>
          </ConditionalFragment>
        </Flexbox>
      </Modal>
      <Flexbox
        align={'center'}
        justify={'between'}
        onClick={toggleOptions}
        className={classNames(
          className,
          'relative',
          { 'py-2.5 px-4': !disablePadding },
          'hoverable:hover:cursor-pointer',
          'w-full',
          { 'rounded-lg border border-solid border-grey-5': !disableBolder }
        )}
      >
        <ConditionalFragment condition={!renderDisplay}>
          <p
            className={classNames('mr-2', 'text-base flex-1 truncate', {
              'text-grey-4': isNotSet(value) || isEmptyString(value),
            })}
          >
            {displayValue || placeholder}
          </p>
        </ConditionalFragment>
        <ConditionalFragment condition={isSet(renderDisplay)}>
          {renderDisplay &&
            React.createElement(renderDisplay, {
              displayValue: displayValue || placeholder,
              hasSelected:
                isSet(value) &&
                ((isString(value) && isNotEmptyString(value)) ||
                  (Array.isArray(value) && isNotEmptyArray(value))),
            })}
        </ConditionalFragment>
        <span
          className={classNames(
            chevronClassName,
            'transform transition-transform',
            {
              'rotate-180': isOptionOpen,
            }
          )}
        >
          <FontAwesomeIcon icon={faChevronDown} className={'w-3.5 h-3.5'} />
        </span>
      </Flexbox>
    </React.Fragment>
  );
};
