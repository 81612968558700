import React, { PropsWithChildren, useEffect } from 'react';
import { Control, FieldPath, useController } from 'react-hook-form';

import classNames from 'classnames';

import Flexbox from '@src/component/common/Flexbox';

interface RadioComponent extends CustomizeFunctionComponent {
  <FormValues, Name extends FieldPath<FormValues>>(
    props: PropsWithChildren<{
      className?: string;
      options: Array<Option>;
      control: Control<FormValues>;
      name: Name;
    }>,
    context?: any
  ): React.ReactElement | null;
}

export const RadioField: RadioComponent = ({
  options,
  control,
  name,
  className,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
  });

  useEffect(() => {
    if (value) return;
    onChange(options[0]?.key);
  }, []);

  return (
    <Flexbox className={className}>
      {options.map(({ key, label }) => {
        const isSelected = value === key;

        return (
          <Flexbox
            as={'label'}
            htmlFor={`radio-${key}`}
            align={'center'}
            justify={'center'}
            key={key}
            className={classNames(
              'flex-1',
              'mr-4 last:mr-0 py-3',
              'min-w-[3rem]',
              'border border-solid rounded-lg',
              {
                'border-grey-5 bg-white': !isSelected,
                'border-pink text-white bg-pink': isSelected,
              }
            )}
          >
            <input
              id={`radio-${key}`}
              type={'radio'}
              value={key}
              onChange={onChange}
              className={'hidden invisible'}
              checked={isSelected}
            />
            {label}
          </Flexbox>
        );
      })}
    </Flexbox>
  );
};
