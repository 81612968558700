import React from 'react';

import classNames from 'classnames';

import { isSet } from '@src/utils';

import ConditionalFragment from '@src/component/common/ConditionalFragment';

export const FieldTitle: FC<{
  isRequired?: boolean;
  rightSide?: React.ReactNode;
}> = ({ isRequired = false, rightSide, children }) => {
  return (
    <p
      className={classNames(
        'mb-1',
        'w-full',
        'text-sm leading-[1.5rem] text-grey-2'
      )}
    >
      {children}
      <ConditionalFragment condition={isRequired}>
        <span className={'text-red'}>*</span>
      </ConditionalFragment>
      <ConditionalFragment condition={isSet(rightSide)}>
        <span className={'float-right'}>{rightSide}</span>
      </ConditionalFragment>
    </p>
  );
};
