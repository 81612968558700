import { PropsWithChildren, ReactElement, useCallback } from 'react';
import {
  Control,
  FieldPath,
  RegisterOptions,
  useController,
} from 'react-hook-form';

import classNames from 'classnames';

import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isFalse, isNotSet, isSet } from '@src/utils';

import Flexbox from '@src/component/common/Flexbox';

interface NumberInputComponent extends CustomizeFunctionComponent {
  <FormValues, Name extends FieldPath<FormValues>>(
    props: PropsWithChildren<
      ElementPropsWithRef<'input'> & {
        control: Control<FormValues>;
        name: Name;
        placeholder?: string;
        rules?: Omit<
          RegisterOptions<FormValues, Name>,
          'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
        >;
        error?: any;
        disableReadOnlyStyle?: string;
        unit?: string;
        step?: number;
      }
    >,
    context?: any
  ): ReactElement<any, any> | null;
}

export const NumberInput: NumberInputComponent = function ({
  className,
  error,
  disableReadOnlyStyle,
  unit,
  step = 1,
  control,
  name,
  rules,
  readOnly,
  ...props
}) {
  const {
    field: { value = 0, onChange },
  } = useController({
    control,
    name,
    rules,
  });

  const onIncrease = useCallback(() => {
    onChange(parseInt(value as string, 10) + step);
  }, [step, value]);

  const onDecrease = useCallback(() => {
    onChange(parseInt(value as string, 10) - step);
  }, [step, value]);

  return (
    <Flexbox align={'center'} className={'relative w-full'}>
      <input
        {...props}
        value={`${value} ${unit}`}
        readOnly
        className={classNames(
          className,
          'py-2.5 px-4',
          'w-full',
          'rounded-lg border border-solid',
          {
            'border-grey-5': isNotSet(error),
            'border-red': isSet(error) && !isFalse(error),
          },
          'text-base placeholder-grey-4',
          { 'read-only:bg-grey-6': !disableReadOnlyStyle && readOnly }
        )}
      />
      <Flexbox
        align={'center'}
        className={classNames(
          'absolute right-4',
          'py-1 px-4',
          'bg-grey-6 rounded-lg'
        )}
      >
        <Flexbox
          as={'button'}
          type={'button'}
          align={'center'}
          justify={'center'}
          className={'w-5 h-5'}
          onClick={onDecrease}
        >
          <FontAwesomeIcon icon={faMinus} className={'w-3 h-3'} />
        </Flexbox>
        <span
          className={classNames('mx-4', 'w-px h-[0.875rem]', 'bg-grey-5')}
        />
        <Flexbox
          as={'button'}
          type={'button'}
          align={'center'}
          justify={'center'}
          className={'w-5 h-5'}
          onClick={onIncrease}
        >
          <FontAwesomeIcon icon={faPlus} className={'w-3 h-3'} />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};
