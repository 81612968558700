import React, {
  ChangeEventHandler,
  PropsWithChildren,
  useCallback,
} from 'react';
import {
  Control,
  FieldPath,
  RegisterOptions,
  useController,
} from 'react-hook-form';

import classNames from 'classnames';

import { faFolderArrowUp, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isNotSet, isSet } from '@src/utils';

import ConditionalFragment from '@src/component/common/ConditionalFragment';
import Flexbox from '@src/component/common/Flexbox';

interface FileInputComponent extends CustomizeFunctionComponent {
  <FormValues, Name extends FieldPath<FormValues>>(
    props: PropsWithChildren<{
      className?: string;
      control: Control<FormValues>;
      name: Name;
      placeholder?: string;
      rules?: Omit<
        RegisterOptions<FormValues, Name>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >;
    }>,
    context?: any
  ): React.ReactElement | null;
}

export const FileInput: FileInputComponent = ({
  className,
  name,
  control,
  rules,
  ...props
}) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
    rules,
  });

  const onFileChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const files = event.target.files;
      if (!files) return;
      const file = files.item(0);
      onChange(file);
      event.target.value = '';
      event.target.files = null;
    },
    []
  );

  const remove = useCallback(() => {
    onChange(null);
  }, []);

  return (
    <div
      {...props}
      className={classNames(
        className,
        'w-full',
        'rounded-lg border border-solid',
        'text-base text-pink',
        {
          'border-pink': isNotSet(value),
          'border-pink-bg-light bg-pink-bg-light': isSet(value),
        }
      )}
    >
      <ConditionalFragment condition={isNotSet(value)}>
        <Flexbox
          as={'label'}
          align={'center'}
          htmlFor={name}
          className={classNames('py-2.5 px-4', 'w-full')}
        >
          <FontAwesomeIcon icon={faFolderArrowUp} className={'mr-2'} />
          選擇檔案
        </Flexbox>
      </ConditionalFragment>
      <ConditionalFragment condition={isSet(value)}>
        <Flexbox
          align={'center'}
          className={classNames('py-2.5 px-4', 'w-full')}
        >
          {(value as File)?.name}
          <Flexbox
            as={'button'}
            type={'button'}
            align={'center'}
            justify={'center'}
            onClick={remove}
            className={
              'w-[1.125rem] h-[1.125rem] ml-auto bg-pink rounded-extreme text-white text-xs'
            }
          >
            <FontAwesomeIcon icon={faTimes} />
          </Flexbox>
        </Flexbox>
      </ConditionalFragment>
      <input
        id={name}
        type={'file'}
        className={'hidden invisible'}
        accept={'image/*'}
        onChange={onFileChange}
      />
    </div>
  );
};
