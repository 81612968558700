import React, { forwardRef, useCallback } from 'react';

import classNames from 'classnames';

import { isEmptyString, isFalse, isNotSet, isSet } from '@src/utils';

export const Textarea = forwardRef<
  HTMLTextAreaElement,
  ElementPropsWithoutRef<'textarea'> & {
    error?: any;
    disableReadOnlyStyle?: string;
    itemization?: boolean;
  }
>(function (
  { className, error, disableReadOnlyStyle, itemization, onChange, ...props },
  ref
) {
  const onTextareaChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement>
  >(
    (event) => {
      let value = event.target.value;
      if (itemization) {
        value = value
          .split('\n')
          .map((p) => {
            if (p.startsWith('\u2022 ')) return p;
            else if (isEmptyString(p) || p === '\u2022 ' || p === '\u2022')
              return p;
            else return `\u2022 ${p}`;
          })
          .join('\n');
        event.target.value = value;
      }
      onChange?.(event);
    },
    [onChange, itemization]
  );

  return (
    <textarea
      ref={ref}
      onChange={onTextareaChange}
      {...props}
      className={classNames(
        className,
        'py-2.5 px-4',
        'w-full h-[7.5rem]',
        'rounded-lg border border-solid',
        'resize-none',
        {
          'border-grey-5': isNotSet(error),
          'border-red': isSet(error) && !isFalse(error),
        },
        'text-base placeholder-grey-4',
        { 'read-only:bg-grey-6': !disableReadOnlyStyle }
      )}
    />
  );
});
