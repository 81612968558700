import React, { forwardRef, PropsWithChildren } from 'react';

import classNames from 'classnames';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ButtonType = 'pink' | 'pink-light' | 'red';

interface Props extends ElementPropsWithRef<'button'> {
  buttonType: ButtonType;
  disableSize?: boolean;
  disableFontSize?: boolean;
  disableRadius?: boolean;
  isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (
    {
      children,
      disableSize,
      disableFontSize,
      disableRadius,
      buttonType,
      className,
      type = 'button',
      isLoading,
      ...buttonProps
    },
    ref
  ) => {
    return (
      <button
        className={classNames(
          className,
          !disableSize && 'flex items-center justify-center h-12',
          !disableFontSize && 'text-base font-bold',
          { 'rounded-extreme': !disableRadius },
          buttonType === 'pink' &&
            'text-white bg-pink-button active:opacity-80 disabled:opacity-30',
          buttonType === 'red' &&
            'text-white bg-red active:opacity-80 disabled:opacity-30',
          buttonType === 'pink-light' &&
            'text-pink bg-pink-bg-light active:bg-opacity-60 disabled:opacity-40'
        )}
        ref={ref}
        type={type}
        {...buttonProps}
      >
        {isLoading ? (
          <FontAwesomeIcon
            icon={faSpinner}
            className={'animate-spin w-4 h-[2.625rem]'}
          />
        ) : (
          children
        )}
      </button>
    );
  }
);

export default Button;

export const FontAwesomeButton: React.FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon: IconProp;
  className?: string;
}> = ({ onClick, icon, className }) => (
  <Button
    disableSize
    buttonType={'pink-light'}
    className={classNames(className, 'w-8 h-8')}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} className={'w-4 h-4'} />
  </Button>
);
